
.lucky-bag {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.inner-content {
    margin: 20px;
    flex: 1;
    height: 1%;
    display: flex;
    flex-direction: column;
    position: relative;
}
.create-lucky-bag {
    width: 178px;
    height: 94px;

    .create-bag-button {
        width: 178px;
        height: 100%;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        border: 0;
        background: url('../../../../assets/image/studentCreateBag/createBagButton.png');
        cursor: pointer;
    }
    .iconfont {
        display: block;
        width: 40px;
        height: 39px;
        margin: 10px 0 8px 60px;
        font-size: 36px;
    }
}
.lucky-bag-list {
    height: 1%;
    flex: 1;
    margin-top: 22px;
}

.el-table {
    position: absolute;
    width: 100%;

    &:before {
        content: none;
    }

    ::v-deep.el-table__empty-block {
        background: #070932;

        .el-table__empty-text {
            color: #fff;
        }
    }

    ::v-deep .el-table__body-wrapper {
        background: #070932;
    }


    ::v-deep th.el-table__cell.is-leaf {
        border-bottom: none;
    }

    ::v-deep td.el-table__cell {
        border-bottom: 1px solid #262963;

        &:first-child {
            border-left: 1px solid #262963;
        }

        &:last-child {
            border-right: 1px solid #262963;
        }
    }
}
::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td{
    background-color: #0C0E3F !important;
}
